import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import './App.css';


import { AnimatePresence } from "framer-motion";

// Components
import MainLayout from "./layout/MainLayout";
import PageAnimation from "./layout/PageAnimation";
import PageFadeAnimation from "./layout/PageFadeAnimation";
import PrivateRoute from "./context/Authentication/PrivateRoute";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Allies from "./pages/Allies/Allies";
import Participa from "./pages/Participa/Participa";
import News from "./pages/News/News";
import DownloadApp from "./pages/App/DownloadApp";
import Panorama from "./pages/Panorama/Panorama";
import CameraGame from "./pages/CameraGame/CameraGame";
import Karens from "./pages/Karens/karens";
import HuevosOro from "./pages/HuevosOro/HuevosOro";
import ARSelfieHuevosOro from "./pages/HuevosOro/ARselfieHuevosOro";
import ARExperience from "./pages/AugmentedReality/ARExperience";
import BirdsCVC from "./pages/AugmentedReality/CVC/BirdsCVC";
import BirdCVCSelfie from "./pages/AugmentedReality/CVC/BirdCVCSelfie";
import Selfie from "./pages/AugmentedReality/Selfie/Selfie";
import ARSelfie from "./pages/AugmentedReality/Selfie/ARSelfie";
import PhotoAR from "./pages/AugmentedReality/PhotoAR/PhotoAR";
import AfichesAR from "./pages/AugmentedReality/AfichesAR/AfichesAR";
import ARSelfieYawa from "./pages/Yawa/ARselfieYawa";
import ARPoemsBook from "./pages/PoemsBook/ARPoemsBook";
import HomePoemsBook from "./pages/PoemsBook/HomePoemsBook";

//GrupoQT
import GrupoTQ from "./pages/GrupoTQ/GrupoTQ";
import ARGrupoTQ from "./pages/GrupoTQ/ARCop16/ARGrupoTQ";

// Camisetas
import ShirtTororoi from "./pages/AugmentedReality/Shirt/ShirtTororoi";


// Context
import { AuthProvider } from './context/Authentication/Auth';

function App() {
  const location = useLocation();

  return (
    <AuthProvider>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/yawa" element={<PageFadeAnimation><ARSelfieYawa /></PageFadeAnimation>} />
          <Route element={<MainLayout />}>
            <Route path="/" element={<PageAnimation><Home /></PageAnimation>} />
            <Route path="/panorama">
              <Route path=":panoId" element={<PageAnimation><Panorama /></PageAnimation>} />
            </Route>
            <Route path="/minijuegos/fotos" element={<PageAnimation><CameraGame /></PageAnimation>} />
            <Route path="/elreinoalado" element={<PageAnimation><About /></PageAnimation>} />
            <Route path="/noticias" element={<PageAnimation><News /></PageAnimation>} />
            <Route path="/aliados" element={<PageAnimation><Allies /></PageAnimation>} />
            <Route path="/participa" element={<PageAnimation><Participa /></PageAnimation>} />
            <Route path="/app" element={<PageAnimation><DownloadApp /></PageAnimation>} />
            <Route path="/autenticacion" element={<PageAnimation><div>Login</div></PageAnimation>} />
            <Route path="/karenspizza" element={<PageAnimation><Karens /></PageAnimation>} />
            <Route path="/huevosoro" element={<PageAnimation><HuevosOro /></PageAnimation>} />
            <Route path="/huevosoro/selfie" element={<PageAnimation><ARSelfieHuevosOro /></PageAnimation>} /> 
            {/* <Route path="/yawa" element={<PageAnimation><ARSelfieYawa /></PageAnimation>} />  */}

            <Route path="/grupotq" element={<PageAnimation><GrupoTQ /></PageAnimation>} />
            <Route path="/grupotq">
              <Route path="cop16" element={<PageAnimation><Selfie /></PageAnimation>} />
              <Route path="cop16/:aveId" element={<PageAnimation><ARGrupoTQ /></PageAnimation>} />
            </Route>

            <Route path="/poemas" element={<PageAnimation><HomePoemsBook /></PageAnimation>} />
            <Route path="/poemas/:aveId" element={<PageAnimation><ARPoemsBook /></PageAnimation>} />

            <Route path="/ar" element={<PageAnimation><ARExperience /></PageAnimation>} />
            <Route path="/ar">
              <Route path="selfie" element={<PageAnimation><Selfie /></PageAnimation>} />
              <Route path="selfie/:aveId" element={<PageAnimation><ARSelfie /></PageAnimation>} />
              <Route path="foto" element={<PageAnimation><PhotoAR /></PageAnimation>} />
              <Route path="foto/:aveId" element={<PageAnimation><ARSelfie /></PageAnimation>} />
              <Route path="cubocop16" element={<Navigate to="/cubocop16" />}/>
              
            </Route>

            <Route path="/cubocop16" element={<PageAnimation><AfichesAR/></PageAnimation>} />

            {/* CAMISETAS */}
            <Route path="/tororoi" element={<PageAnimation><ShirtTororoi/></PageAnimation>} />
            
            <Route
              path="/protected"
              element={
                <PrivateRoute>
                  <div>Página privada</div>
                </PrivateRoute>
              }
            />
          </Route>

          <Route path="/cvc" element={<PageAnimation><BirdsCVC /></PageAnimation>} />
          <Route path="/cvc">
            <Route path="selfie/:aveId" element={<PageFadeAnimation><BirdCVCSelfie /></PageFadeAnimation>} />
          </Route>
        </Routes>
      </AnimatePresence>
    </AuthProvider>
  );
}

export default App;
