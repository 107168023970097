import React from 'react'

const HomePoemsBook = () => {
    return (
        <div className='h-screen w-full flex justify-center items-center'>
            <video className="mt-10 max-h-full max-w-full h-3/4 rounded-lg" 
                controls 
                controlsList="nodownload noremoteplayback"
                playsInline
                autoPlay
            >
                <source
                    src="/videos/poemsbook/home.mp4"
                    type="video/mp4"
                />
                Lo sentimos, tu navegador no soporta la reproducción de video.
            </video>
        </div>
    );
}

export default HomePoemsBook;