import React, { useEffect, useRef, useState } from 'react';

const ARScreenshotView = ({loading = true, title, message}) => {
    const [capturedImage, setCapturedImage] = useState(null);
    const [loadingScreenshot, setLoadingScreenshot] = useState(false);


    const modalRef = useRef(null)

    // activo el modal cuando se tome una captura
    useEffect(() => {
        if (!capturedImage) return
        modalRef.current.showModal();
        // console.log(modalRef)
    }, [capturedImage])

    const takeScreenshot = async () => {
        try {
            // Establecer que la captura está en proceso
            setLoadingScreenshot(true);

            // Esperar un ciclo de renderizado para actualizar el estado
            await new Promise(resolve => setTimeout(resolve, 0));

            // Obtener el canvas de la escena
            const sceneEl = document.querySelector('a-scene');
            const sceneCanvas = await new Promise((resolve, reject) => {
                const canvas = sceneEl.components.screenshot.getCanvas('perspective');
                if (canvas) resolve(canvas);
                else reject(new Error("Error al obtener el canvas de la escena"));
            });

            const video = document.querySelector('video');

            // Obtener las dimensiones del canvas de la escena
            const { width, height } = sceneEl.getBoundingClientRect();

            // Crear un nuevo canvas para combinar ambas imágenes
            const combinedCanvas = document.createElement('canvas');
            combinedCanvas.width = width;
            combinedCanvas.height = height;
            const context = combinedCanvas.getContext('2d');

            const sceneCanvasWidth = sceneCanvas.width;
            const sceneCanvasHeight = sceneCanvas.height;

            if (video) {
                const videoWidth = video.videoWidth;
                const videoHeight = video.videoHeight;

                // Calcular el recorte del video basado en las dimensiones del canvas combinado
                const videoAspect = videoWidth / videoHeight;
                const canvasAspect = width / height;
                let sx = 0, sy = 0, sWidth = videoWidth, sHeight = videoHeight;

                if (videoAspect > canvasAspect) {
                    sWidth = videoHeight * videoAspect;
                    sx = (videoWidth - sWidth) / 2;
                } else if (videoAspect < canvasAspect) {
                    sHeight = videoWidth / videoAspect;
                    sy = (videoHeight - sHeight) / 2;
                }

                // Verificar si el video tiene la transformación scaleX(-1)
                const videoStyle = getComputedStyle(video);
                const isFlipped = videoStyle.transform.includes('matrix(-1');

                if (isFlipped) {
                    context.save();
                    context.scale(-1, 1);
                    context.translate(-width, 0);
                }

                // Dibujar el video en el canvas combinado
                context.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, width, height);

                if (isFlipped) {
                    context.restore();
                }
            }

            // Dibujar el canvas de A-Frame en el canvas combinado
            context.drawImage(sceneCanvas, 0, 0, sceneCanvasWidth, sceneCanvasHeight, 0, 0, width, height);

            // Obtener la imagen combinada como Data URL
            const dataUrl = combinedCanvas.toDataURL('image/png');
            setCapturedImage(dataUrl);
        } catch (error) {
            console.error("Error al tomar el screenshot:", error);
        } finally {
            // Indicar que la captura ha terminado
            setLoadingScreenshot(false);
        }
    };

    const downloadImage = () => {
        if (capturedImage) {
            const link = document.createElement('a');
            link.href = capturedImage;
            link.download = 'screenshot.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const shareImage = () => {
        if (navigator.share && capturedImage) {
            fetch(capturedImage)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], 'screenshot.png', { type: 'image/png' });
                    navigator.share({
                        files: [file],
                        title: title || 'Foto con el ave',
                        text: message || '¡Vive la magia del Reino Alado! \n\n Visita la Colombia Birdfair 2025',
                    });
                });
        }
    };

    return (
        <div className='absolute w-full h-full z-10 flex align-bottom justify-center items-end p-5'>
            
            {/* <button className="btn btn-primary absolute bottom-4 left-1/2" onClick={takeScreenshot}>Foto</button> */}

            <button className="btn btn-circle bg-white text-primary btn-lg"
                onClick={takeScreenshot}
                disabled={loadingScreenshot}
            >
                {
                    loadingScreenshot ?
                        <span className="loading loading-spinner"></span>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg"
                            // width="40" height="40"
                            className='h-10 w-10'
                            viewBox="0 0 512 512">
                            <circle cx="256" cy="272" r="64" fill="#070062" />
                            <path fill="#070062" d="M432 144h-59c-3 0-6.72-1.94-9.62-5l-25.94-40.94a15.5 15.5 0 0 0-1.37-1.85C327.11 85.76 315 80 302 80h-92c-13 0-25.11 5.76-34.07 16.21a15.5 15.5 0 0 0-1.37 1.85l-25.94 41c-2.22 2.42-5.34 5-8.62 5v-8a16 16 0 0 0-16-16h-24a16 16 0 0 0-16 16v8h-4a48.05 48.05 0 0 0-48 48V384a48.05 48.05 0 0 0 48 48h352a48.05 48.05 0 0 0 48-48V192a48.05 48.05 0 0 0-48-48M256 368a96 96 0 1 1 96-96a96.11 96.11 0 0 1-96 96" />
                        </svg>

                        // <svg xmlns="http://www.w3.org/2000/svg" 
                        //     className='h-10 w-10'
                        //     viewBox="0 0 24 24">
                        //         <path fill="#070062" d="M11.5 8C14 8 16 10 16 12.5S14 17 11.5 17S7 15 7 12.5S9 8 11.5 8m0 1A3.5 3.5 0 0 0 8 12.5a3.5 3.5 0 0 0 3.5 3.5a3.5 3.5 0 0 0 3.5-3.5A3.5 3.5 0 0 0 11.5 9M5 5h2l2-2h5l2 2h2a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3m4.41-1l-2 2H5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-2.41l-2-2z" />
                        // </svg>
                        
                }

            </button>

            <dialog id="screenshot-modal" className="modal modal-bottom sm:modal-middle" ref={modalRef}>
                <div className="modal-box flex flex-col">

                    <form method="dialog" className='m-4'>
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-3 top-3">✕</button>
                    </form>
                    <img src={capturedImage} alt="AR Screenshot" className='max-w-full w-full object-contain rounded-lg' />
                    <div className="mt-4 flex gap-3 justify-center">
                        <button className="btn btn-primary" onClick={shareImage}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' viewBox="0 0 24 24"><path fill="#fff" d="M17 22q-1.25 0-2.125-.875T14 19q0-.15.075-.7L7.05 14.2q-.4.375-.925.588T5 15q-1.25 0-2.125-.875T2 12t.875-2.125T5 9q.6 0 1.125.213t.925.587l7.025-4.1q-.05-.175-.062-.337T14 5q0-1.25.875-2.125T17 2t2.125.875T20 5t-.875 2.125T17 8q-.6 0-1.125-.213T14.95 7.2l-7.025 4.1q.05.175.063.338T8 12t-.012.363t-.063.337l7.025 4.1q.4-.375.925-.587T17 16q1.25 0 2.125.875T20 19t-.875 2.125T17 22" /></svg>
                            Compartir
                        </button>

                        <button className="btn btn-primary" onClick={downloadImage}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' viewBox="0 0 24 24"><path fill="#fff" d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" /></svg>
                            Descargar
                        </button>

                        {/* <button className="btn btn-error" onClick={() => modalRef?.current?.close()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' viewBox="0 0 24 24"><path fill="#fff" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z" /></svg>
                            Cerrar
                        </button> */}
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default ARScreenshotView